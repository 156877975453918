import React, { lazy } from 'react';

const CostCategoryListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCategoryModule/CostCategoryListing'
  ).then(module => ({
    default: module.CostCategoryListing,
  })),
);

const CostCategoryForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCategoryModule/CostCategoryForm'
  ).then(module => ({
    default: module.CostCategoryForm,
  })),
);

const costCategoryRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/:costCategory' },
    component: <CostCategoryListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/building-material/add',
    },
    component: <CostCategoryForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/:mode/general-settings/building-material/:costCategoryID/edit',
    },
    component: <CostCategoryForm mode="edit" />,
  },
];

export default costCategoryRoutes;
