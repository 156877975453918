import React, { lazy } from 'react';

const NotFound = lazy(() =>
  import('./NotFound').then(module => ({
    default: module.NotFound,
  })),
);

const Login = lazy(() =>
  import('./Login').then(module => ({
    default: module.Login,
  })),
);
const ForgotPassword = lazy(() =>
  import('./Password/ForgotPassword').then(module => ({
    default: module.ForgotPassword,
  })),
);

const ChangePassword = lazy(() =>
  import('./Password/ChangePassword').then(module => ({
    default: module.ChangePassword,
  })),
);

const ResetPassword = lazy(() =>
  import('./Password/ResetPassword').then(module => ({
    default: module.ResetPassword,
  })),
);
const Profile = lazy(() =>
  import('./Profile/Profile').then(module => ({
    default: module.Profile,
  })),
);
const EditProfile = lazy(() =>
  import('./Profile/EditProfile').then(module => ({
    default: module.EditProfile,
  })),
);
const Logout = lazy(() =>
  import('./Logout').then(module => ({
    default: module.Logout,
  })),
);

const UserActivation = lazy(() =>
  import('./ActivatedUser').then(module => ({
    default: module.ActivatedUser,
  })),
);

const CreatePassword = lazy(() =>
  import('./Password/CreatePassword').then(module => ({
    default: module.CreatePassword,
  })),
);

const authenticationRoutes = [
  {
    props: { path: '/user/activate/:token' },
    component: <UserActivation />,
  },
  {
    props: { path: '/user/create-password/:token' },
    component: <CreatePassword />,
  },
  {
    props: { path: '/profile/change-password' },
    component: <ChangePassword />,
  },
  {
    props: { path: `/reset-password/:token` },
    component: <ResetPassword />,
  },
  {
    props: { path: '/profile/edit' },
    component: <EditProfile />,
  },
  {
    props: { path: '/profile' },
    component: <Profile />,
  },
  {
    props: { path: '/authentication/404' },
    component: <NotFound />,
  },
  {
    props: { path: '/logout' },
    component: <Logout />,
  },
  {
    props: { path: '/login' },
    component: <Login />,
  },
  {
    props: { path: '/forgot' },
    component: <ForgotPassword />,
  },
];

export default authenticationRoutes;
