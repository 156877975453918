import React, { lazy } from 'react';

//Site Inventory Digital Reporting
const SiteInventoryReportingSubmenu = lazy(() =>
  import('./SiteInventoryReportingSubmenu').then(module => ({
    default: module.SiteInventoryReportingSubmenu,
  })),
);

const StockMovementLedgerReport = lazy(() =>
  import('./PreviewPage/StockMovementLedgerReport').then(module => ({
    default: module.StockMovementLedgerReport,
  })),
);

const StockMovementLedgerReportParameter = lazy(() =>
  import(
    './SiteInventoryReportParameterForm/StockMovementLedgerReportParameter'
  ).then(module => ({
    default: module.StockMovementLedgerReportParameter,
  })),
);

const siteInventoryReportingRoutes = [
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/digital-reporting',
    },
    component: <SiteInventoryReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/digital-reporting/parameter/ledger-report',
    },
    component: <StockMovementLedgerReportParameter />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/digital-reporting/ledger-report',
    },
    component: <StockMovementLedgerReport />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/digital-reporting',
    },
    component: <SiteInventoryReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/site-inventory/submenu/:siteID/digital-reporting/parameter/ledger-report',
    },
    component: <StockMovementLedgerReportParameter />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/digital-reporting/ledger-report',
    },
    component: <StockMovementLedgerReport />,
  },
];

export default siteInventoryReportingRoutes;
