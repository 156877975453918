import React, { lazy } from 'react';
import { DefectAssignmentListing } from './DefectAssignment/DefectAssignmentListing';

const DefectManagementListing = lazy(() =>
  import('./DefectManagementListing').then(module => ({
    default: module.DefectManagementListing,
  })),
);

const DefectManagementForm = lazy(() =>
  import('./DefectManagementForm').then(module => ({
    default: module.DefectManagementForm,
  })),
);

const DefectManagementDetail = lazy(() =>
  import('./DefectManagementDetail').then(module => ({
    default: module.DefectManagementDetail,
  })),
);

//Activity Log
const ActivityLogListing = lazy(() =>
  import('./ActivityLog/ActivityLogListing').then(module => ({
    default: module.ActivityLogListing,
  })),
);

const ActivityLogForm = lazy(() =>
  import('./ActivityLog/ActivityLogForm').then(module => ({
    default: module.ActivityLogForm,
  })),
);

const DefectAssignment = lazy(() =>
  import('./DefectAssignment/DefectAssignmentListing').then(module => ({
    default: module.DefectAssignmentListing,
  })),
);

const defectManagementRoutes = [
  {
    props: { exact: true, path: '/defect' },
    component: <DefectManagementListing />,
  },
  {
    props: { exact: true, path: '/defect/:siteID' },
    component: <DefectManagementListing />,
  },
  {
    props: { exact: true, path: '/defect/:siteID/add' },
    component: <DefectManagementForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/defect/:siteID/edit/:defectID',
    },
    component: <DefectManagementForm mode="edit" />,
  },
  {
    props: { exact: true, path: '/defect/:siteID/detail/:defectID' },
    component: <DefectManagementDetail />,
  },
  {
    props: {
      exact: true,
      path: '/defect/:siteID/detail/:defectID/activity-log',
    },
    component: <ActivityLogListing />,
  },
  {
    props: {
      exact: true,
      path: '/defect/:siteID/detail/:defectID/activity-log/add',
    },
    component: <ActivityLogForm />,
  },
  {
    props: {
      exact: true,
      path: '/defect/:siteID/detail/:defectID/defect-assignment',
    },
    component: <DefectAssignmentListing />,
  },
];

export default defectManagementRoutes;
