import React, { lazy } from 'react';

const CalendarPolicyListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CalendarPolicyModule/CalendarPolicyListing'
  ).then(module => ({
    default: module.CalendarPolicyListing,
  })),
);

const CalendarPolicyForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CalendarPolicyModule/CalendarPolicyForm'
  ).then(module => ({
    default: module.CalendarPolicyForm,
  })),
);

const calendarPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/calendar-policy',
    },
    component: <CalendarPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/calendar-policy/add',
    },
    component: <CalendarPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/calendar-policy/:calendarPolicyID/edit',
    },
    component: <CalendarPolicyForm mode="edit" />,
  },
];

export default calendarPolicyRoutes;
