import authenticationRoutes from 'containers/AccountModule/AccountRoutes';
import businessInsightRoutes from 'containers/BusinessInsightModule/BusinessInsightRoutes';
import contractSettingRoutes from 'containers/ContractSettingsModule/ContractSettingsRoutes';
import defectManagementRoutes from 'containers/DefectManagementModule/DefectManagementRoutes';
import defectMgmtRoutes from 'containers/DefectMgmtModule/DefectMgmtRoutes';
import eDocRoutes from 'containers/EDocModule/EDocRoutes';
import generalSettingRoutes from 'containers/GeneralSettingModule/GeneralSettingRoutes';
import siteAttendanceRoutes from 'containers/SiteAttendanceModule/SiteAttendanceRoutes';
import siteDiaryRoutes from 'containers/SiteDiaryModule/SiteDiaryRoutes';
import commonSettingsRoutes from 'containers/SiteInventoryModule/SiteInventoryApproval/CommonSettingsApproval/CommonSettingsRoutes';
import siteMaterialPolicyRoutes from 'containers/SiteInventoryModule/SiteInventoryApproval/SiteMaterialPolicy/SiteMaterialPolicyRoutes';
import siteInventoryRoutes from 'containers/SiteInventoryModule/SiteInventoryRoute';
import siteManagementRoutes from 'containers/SiteManagementModule/SiteManagementRoutes';
import siteSafetyRoutes from 'containers/SiteSafetyModule/SiteSafetyRoutes';
import siteSurveillanceRoutes from 'containers/SiteSurveillanceModule/SiteSurveillanceRoutes';
import siteWagesRoutes from 'containers/SiteWagesModule/SiteWagesRoutes';
import subordinateAssignmentRoutes from 'containers/SubordinateAssignmentModule/SubordinateAssignmentRoutes';
import systemAdminRoutes from 'containers/SystemAdminModule/SystemAdminRoutes';
import toDoRoutes from 'containers/ToDoModule/ToDoRoutes';
import { Redirect } from 'react-router-dom';
import homeRoutes from '../../HomeModule/HomeRoutes';
import React from 'react';

const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
];

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
];

const routes = [
  ...homeRoutes,
  ...systemAdminRoutes,
  ...generalSettingRoutes,
  ...contractSettingRoutes,
  ...commonSettingsRoutes,
  ...siteMaterialPolicyRoutes,
  ...defectManagementRoutes,
  ...defectMgmtRoutes,
  ...siteAttendanceRoutes,
  ...siteInventoryRoutes,
  ...subordinateAssignmentRoutes,
  ...businessInsightRoutes,
  ...siteWagesRoutes,
  ...siteSurveillanceRoutes,
  ...siteManagementRoutes,
  ...eDocRoutes,
  ...toDoRoutes,
  ...siteDiaryRoutes,
  ...siteSafetyRoutes,
  ...authenticationRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
