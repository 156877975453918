import React, { lazy } from 'react';
import siteInventoryReportingRoutes from './DigitalReporting/SiteInventoryReportingRoutes';

const SiteInventorySubmenu = lazy(() =>
  import('./SiteInventorySubmenu').then(module => ({
    default: module.SiteInventorySubmenu,
  })),
);

const GoodReceiveNoteListing = lazy(() =>
  import('./GoodReceiveNote/GoodReceiveNoteListing').then(module => ({
    default: module.GoodReceiveNoteListing,
  })),
);
const GoodReceiveNoteForm = lazy(() =>
  import('./GoodReceiveNote/GoodReceiveNoteForm').then(module => ({
    default: module.GoodReceiveNoteForm,
  })),
);

const GoodReceiveNoteDetail = lazy(() =>
  import('./GoodReceiveNote/GoodReceiveNoteDetail').then(module => ({
    default: module.GoodReceiveNoteDetail,
  })),
);

const StockMovementCategoryListing = lazy(() =>
  import('./StockMovement/StockMovementCategoryListing').then(module => ({
    default: module.StockMovementCategoryListing,
  })),
);

const StockMovementDetailListing = lazy(() =>
  import('./StockMovement/StockMovementDetailListing').then(module => ({
    default: module.StockMovementDetailListing,
  })),
);

const StockTrackingListing = lazy(() =>
  import('./StockTracking/StockTrackingListing').then(module => ({
    default: module.StockTrackingListing,
  })),
);

const StockTrackingPDFPreview = lazy(() =>
  import('./StockTracking/MobilePreview/StockTrackingPDFPreview').then(
    module => ({
      default: module.StockTrackingPDFPreview,
    }),
  ),
);

const StockUsageAnalysisListing = lazy(() =>
  import('./StockUsageAnalysis/StockUsageAnalysisListing').then(module => ({
    default: module.StockUsageAnalysisListing,
  })),
);

const SiteInventoryRoute = [
  ...siteInventoryReportingRoutes,
  /////////////////////////////
  ////// WITHOUT SITE ID //////
  /////////////////////////////
  {
    props: { exact: true, path: '/site-inventory/submenu' },
    component: <SiteInventorySubmenu />,
  },
  //--GRN--//
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/good-receive-note',
    },
    component: <GoodReceiveNoteListing />,
  },
  //--Stock Tracking--//
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/stock-issue',
    },
    component: <StockTrackingListing stockCategory="stock-issue" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/stock-transfer-in',
    },
    component: <StockTrackingListing stockCategory="stock-transfer-in" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/stock-transfer-out',
    },
    component: <StockTrackingListing stockCategory="stock-transfer-out" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/stock-adjustment',
    },
    component: <StockTrackingListing stockCategory="stock-adjustment" />,
  },
  //--Stock Movement--//
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/stock-category',
    },
    component: <StockMovementCategoryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/site-inventory/submenu/stock-category/:siteMaterialID/stock-movement',
    },
    component: <StockMovementDetailListing />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/stock-category/:costItemID/stock-movement',
    },
    component: <StockMovementDetailListing />,
  },
  //--Stock Usage Analysis--//
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/stock-usage-analysis',
    },
    component: <StockUsageAnalysisListing />,
  },

  //////////////////////////
  ////// WITH SITE ID //////
  //////////////////////////
  {
    props: { exact: true, path: '/site-inventory/submenu/:siteID' },
    component: <SiteInventorySubmenu />,
  },
  //--GRN--//
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/good-receive-note',
    },
    component: <GoodReceiveNoteListing />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/good-receive-note/form',
    },
    component: <GoodReceiveNoteForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/good-receive-note/form/:grnID',
    },
    component: <GoodReceiveNoteForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/good-receive-note/:grnID/resubmit',
    },
    component: <GoodReceiveNoteForm mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/good-receive-note/detail/:grnID',
    },
    component: <GoodReceiveNoteDetail />,
  },
  //-Stock Usage Analysis--//
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/stock-usage-analysis',
    },
    component: <StockUsageAnalysisListing />,
  },
  //--Stock Tracking--//
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/stock-issue',
    },
    component: <StockTrackingListing stockCategory="stock-issue" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/stock-transfer-in',
    },
    component: <StockTrackingListing stockCategory="stock-transfer-in" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/stock-transfer-out',
    },
    component: <StockTrackingListing stockCategory="stock-transfer-out" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/stock-adjustment',
    },
    component: <StockTrackingListing stockCategory="stock-adjustment" />,
  },
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/:stockCategory/preview',
    },
    component: <StockTrackingPDFPreview />,
  },
  //--Stock Movement--//
  {
    props: {
      exact: true,
      path: '/site-inventory/submenu/:siteID/stock-category',
    },
    component: <StockMovementCategoryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/site-inventory/submenu/:siteID/stock-category/:siteMaterialID/stock-movement',
    },
    component: <StockMovementDetailListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/site-inventory/submenu/:siteID/stock-category/:costItemID/stock-movement',
    },
    component: <StockMovementDetailListing />,
  },
];

export default SiteInventoryRoute;
