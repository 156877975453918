import routes from '../Router/RootRoutes'

interface iGlobalState {
  routes: any
  drawerOpen: boolean
  isAuth: boolean
  isPathSysAdmin: boolean
  isSiteInventory: boolean
  mode: string
  isLoggedOut: boolean
}
export const GlobalInitialState: iGlobalState = {
  routes: routes,
  drawerOpen: true,
  isAuth: false,
  isPathSysAdmin: false,
  isSiteInventory: false,
  mode: '',
  isLoggedOut: false,
}

export function RootReducer(state, action) {
  switch (action.type) {
    case 'routes':
      return {
        ...state,
        routes: action.payload,
      }
    case 'drawerOpen':
      return { ...state, drawerOpen: action.payload }
    case 'isAuth':
      return {
        ...state,
        isAuth: action.payload,
      }
    case 'mode':
      return { ...state, mode: action.payload }
    case 'isPathSysAdmin':
      return { ...state, isPathSysAdmin: action.payload }
    case 'isSiteInventory':
      return { ...state, isSiteInventory: action.payload }
    default:
      throw new Error()
  }
}
