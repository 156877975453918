import React, { lazy } from 'react';

//WorkingHours//
const WorkerProfileListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WorkerProfileModule/WorkerProfileListing'
  ).then(module => ({
    default: module.WorkerProfileListing,
  })),
);

const WorkerProfileForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WorkerProfileModule/WorkerProfileForm'
  ).then(module => ({
    default: module.WorkerProfileForm,
  })),
);

const workerProfileRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/worker-profile' },
    component: <WorkerProfileListing />,
  },
  {
    props: { exact: true, path: '/:mode/general-settings/worker-profile/form' },
    component: <WorkerProfileForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/worker-profile/:workerID/form',
    },
    component: <WorkerProfileForm mode="edit" />,
  },
];

export default workerProfileRoutes;
