import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import Logo from 'assets/images/contractxWhite.png';
import Background from 'assets/images/HR-Background.jpg';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { useActivateUserMutation } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

export const ActivatedUser = () => {
  const { token }: any = useParams();

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // const jwtDecodeToken = jwtDecode(token);
  // const handleRedirectPath = jwtDecodeToken => {
  //   let path;
  //   switch (jwtDecodeToken?.softwareCode[0]) {
  //     case SoftwareCode.Contract:
  //       path = 'https://contractx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Hr:
  //       path = 'https://hrx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Property:
  //       path = 'https://propertyx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Hotel:
  //       path = 'https://hotelx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Rent:
  //       path = 'https://rentx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Account:
  //       path = 'https://accountx-dev.ifca.io/login';
  //       break;
  //     default:
  //       path = null;
  //       break;
  //   }
  //   return window.location.assign(path);
  // };
  let history = useHistory();
  const [
    ActivatedUser,
    { loading: mutationUpdateLoading, error: mutationUpdateError, data },
  ] = useActivateUserMutation({
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: data => {
      if (data?.activateUser && token) {
        history.push(`/user/create-password/${token}`);
      } else {
        console.log('mutation return false');
      }
    },
  });

  useEffect(() => {
    if (token) {
      ActivatedUser({
        variables: {
          token: token,
        },
      });
    }
  }, [token]);

  return (
    <>
      {mutationUpdateLoading && <Loading />}
      <AuthLayout logo={Logo} image={Background}>
        {data?.activateUser ? (
          <div className="form-box">
            <span className="page-title">You're almost there!</span>
            <br />
            <span className="page-title">Redirecting...</span>
            {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={() => {
            history.push(`/user/create-password/${token}`);
          }}
          >
          Create password
        </Button> */}
          </div>
        ) : (
          <div className="form-box">
            <span className="page-title">Something went wrong!</span>
            {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={() => {
            history.push(`/user/create-password/${token}`);
          }}
          >
          Create password
        </Button> */}
          </div>
        )}
      </AuthLayout>

      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg="Activated User Setup Error"
      />
    </>
  );
};
