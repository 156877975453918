import React, { lazy } from 'react';

const SiteMaterialListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/SiteMaterialModule/SiteMaterialListing'
  ).then(module => ({
    default: module.SiteMaterialListing,
  })),
);

const siteMaterialRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/site-material',
    },
    component: <SiteMaterialListing />,
  },
];

export default siteMaterialRoutes;
