import React, { lazy } from 'react';
// import {SubordinateAssignmentListing} from './SubordinateAssignmentListing';

const SubordinateAssignmentListing = lazy(() =>
  import('./SubordinateAssignmentListing').then(module => ({
    default: module.SubordinateAssignmentListing,
  })),
);
const SubordinateAssignmentForm = lazy(() =>
  import('./SubordinateAssignmentForm').then(module => ({
    default: module.SubordinateAssignmentForm,
  })),
);

const subordinateAssignmentRoutes = [
  {
    props: { exact: true, path: '/subordinate-assignment' },
    component: <SubordinateAssignmentListing />,
  },
  {
    props: { exact: true, path: '/subordinate-assignment/:siteID' },
    component: <SubordinateAssignmentListing />,
  },
  {
    props: { exact: true, path: '/subordinate-assignment/:siteID/add' },
    component: <SubordinateAssignmentForm mode={'add'} />,
  },
];

export default subordinateAssignmentRoutes;
