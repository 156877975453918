import React, { lazy } from 'react';

//VideoCams//
const VideoCamListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/VideoCamModule/VideoCamListing'
  ).then(module => ({
    default: module.VideoCamListing,
  })),
);

const videoCamRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/video-cam',
    },
    component: <VideoCamListing />,
  },
];

export default videoCamRoutes;
