import React, { lazy } from 'react'

// Defect //
const DefMgmtContractListing = lazy(() =>
  import('./DefMgmtContractListing').then(module => ({
    default: module.DefMgmtContractListing,
  }))
)

const DefectMgmtListing = lazy(() =>
  import('./DefectMgmtListing').then(module => ({
    default: module.DefectMgmtListing,
  }))
)

const DefectMgmtForm = lazy(() =>
  import('./DefectMgmtForm').then(module => ({
    default: module.DefectMgmtForm,
  }))
)

const DefectMgmtDetail = lazy(() =>
  import('./DefectMgmtDetail').then(module => ({
    default: module.DefectMgmtDetail,
  }))
)

// Activity Log //
const ActivityLogListing = lazy(() =>
  import('./ActivityLog/ActivityLogListing').then(module => ({
    default: module.ActivityLogListing,
  }))
)

const ActivityLogForm = lazy(() =>
  import('./ActivityLog/ActivityLogForm').then(module => ({
    default: module.ActivityLogForm,
  }))
)

const defectMgmtRoutes = [
  {
    props: { exact: true, path: '/defect-management' },
    component: <DefMgmtContractListing />,
  },
  {
    props: { exact: true, path: '/defect-management/:contractID' },
    component: <DefectMgmtListing />,
  },
  {
    props: { exact: true, path: '/defect-management/:contractID/add' },
    component: <DefectMgmtForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/defect-management/:contractID/edit/:defectID',
    },
    component: <DefectMgmtForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/defect-management/:contractID/detail/:defectID',
    },
    component: <DefectMgmtDetail />,
  },
  {
    props: {
      exact: true,
      path: '/defect-management/:contractID/activity-log/:defectID',
    },
    component: <ActivityLogListing />,
  },
  {
    props: {
      exact: true,
      path: '/defect-management/:contractID/activity-log/:defectID/add',
    },
    component: <ActivityLogForm mode="add" />,
  },
]

export default defectMgmtRoutes
