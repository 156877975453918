import React, { lazy } from 'react';

const ProjectSiteListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/ProjectSiteModule/ProjectSiteListing'
  ).then(module => ({
    default: module.ProjectSiteListing,
  })),
);

const ProjectSiteForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/ProjectSiteModule/ProjectSiteForm'
  ).then(module => ({
    default: module.ProjectSiteForm,
  })),
);

const ProjectSiteDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/ProjectSiteModule/ProjectSiteDetail'
  ).then(module => ({
    default: module.ProjectSiteDetail,
  })),
);

const projectSiteRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/project-site',
    },
    component: <ProjectSiteListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/project-site/project-site-form',
    },
    component: <ProjectSiteForm />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/project-site/add',
    },
    component: <ProjectSiteForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/project-site/:projectSiteID/edit',
    },
    component: <ProjectSiteForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/project-site/:projectSiteID/detail',
    },
    component: <ProjectSiteDetail />,
  },
];

export default projectSiteRoutes;
