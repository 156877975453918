import React, { lazy } from 'react'

// Main Listing & Submenu
const SiteManagementListing = lazy(() =>
  import('./SiteManagementListing').then(module => ({
    default: module.SiteManagementListing,
  }))
)

const SiteManagementSubmenu = lazy(() =>
  import('./SiteManagementSubmenu').then(module => ({
    default: module.SiteManagementSubmenu,
  }))
)

// Site Worker
const SiteWorkerAttendance = lazy(() =>
  import('./SiteWorker/SiteWorkerAttendance').then(module => ({
    default: module.SiteWorkerAttendance,
  }))
)

// Site Stock
const MaterialOnSiteListing = lazy(() =>
  import('./MaterialOnSite/MaterialOnSiteListing').then(module => ({
    default: module.MaterialOnSiteListing,
  }))
)

const MaterialOnSiteTracking = lazy(() =>
  import('./MaterialOnSite/MaterialOnSiteTracking').then(module => ({
    default: module.MaterialOnSiteTracking,
  }))
)

// Live Videocam
const LiveVideocamView = lazy(() =>
  import('./LiveVideocam/LiveVideocamView').then(module => ({
    default: module.LiveVideocamView,
  }))
)

// Site Progress
const SiteProgressListing = lazy(() =>
  import('./SiteProgress/SiteProgressListing').then(module => ({
    default: module.SiteProgressListing,
  }))
)

const SiteProgressForm = lazy(() =>
  import('./SiteProgress/SiteProgressForm').then(module => ({
    default: module.SiteProgressForm,
  }))
)

const siteManagementRoutes = [
  // Main Listing and Submenu
  {
    props: { exact: true, path: '/site-management' },
    component: <SiteManagementListing />,
  },
  {
    props: { exact: true, path: '/site-management/:siteID/submenu' },
    component: <SiteManagementSubmenu />,
  },

  // Site Worker
  {
    props: {
      exact: true,
      path: '/site-management/:siteID/submenu/site-worker-attendance',
    },
    component: <SiteWorkerAttendance />,
  },
  // Site Stock
  {
    props: {
      exact: true,
      path: '/site-management/:siteID/submenu/material-on-site',
    },
    component: <MaterialOnSiteListing />,
  },
  {
    props: {
      exact: true,
      path: '/site-management/:siteID/submenu/material-on-site/:siteStockID',
    },
    component: <MaterialOnSiteTracking />,
  },

  //Live Videocam
  {
    props: {
      exact: true,
      path: '/site-management/:siteID/submenu/live-videocam',
    },
    component: <LiveVideocamView />,
  },

  // Site Progress
  {
    props: {
      exact: true,
      path: '/site-management/:siteID/submenu/site-progress',
    },
    component: <SiteProgressListing />,
  },
  {
    props: {
      exact: true,
      path: '/site-management/:siteID/submenu/site-progress/add',
    },
    component: <SiteProgressForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/site-management/:siteID/submenu/site-progress/edit/:siteProgressID',
    },
    component: <SiteProgressForm mode="edit" />,
  },
]

export default siteManagementRoutes
