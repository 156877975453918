import React, { lazy } from 'react';

const ContractSettingListing = lazy(() =>
  import('./ContractSettingListing').then(module => ({
    default: module.ContractSettingListing,
  })),
);

const SiteApprovalPolicyListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/SiteApprovalPolicy/SiteApprovalPolicyListing'
  ).then(module => ({
    default: module.SiteApprovalPolicyListing,
  })),
);
const SiteApprovalPolicyForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/SiteApprovalPolicy/SiteApprovalPolicyForm'
  ).then(module => ({
    default: module.SiteApprovalPolicyForm,
  })),
);

const SiteContractDetail = lazy(() =>
  import('./ContractDetails').then(module => ({
    default: module.ContractDetails,
  })),
);

const commonApprovalRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings/:contractID/site-approval-policy/policy-list',
    },
    component: <SiteApprovalPolicyListing contractSettings />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings/:contractID/site-approval-policy/policy-list/:refName/approval-assignment',
    },
    component: <SiteApprovalPolicyForm contractSettings />,
  },
];

const SiteMaterialPolicyListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/SiteMaterialModule/ApprovalPolicy/SiteMaterialPolicyListing'
  ).then(module => ({
    default: module.SiteMaterialPolicyListing,
  })),
);

const VideoCamListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/VideoCamModule/VideoCamListing'
  ).then(module => ({
    default: module.VideoCamListing,
  })),
);

const contractSettingRoutes = [
  {
    props: { exact: true, path: '/:mode/contract-settings' },
    component: <ContractSettingListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings/:contractID/settings-listing',
    },
    component: <SiteContractDetail />,
  },
  {
    props: { exact: true, path: '/:mode/:contractID/site-material-policy' },
    component: <SiteMaterialPolicyListing contractSettings />,
  },
  {
    props: { exact: true, path: '/:mode/:contractID/video-cam' },
    component: <VideoCamListing contractSettings />,
  },
  ...commonApprovalRoutes,
];

export default contractSettingRoutes;
