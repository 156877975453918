import React, { lazy } from 'react';

const HomeMenu = lazy(() =>
  import('./HomeMenu').then(module => ({
    default: module.HomeMenu,
  })),
);

const HomeGroup = lazy(() =>
  import('./HomeGroup').then(module => ({
    default: module.HomeGroup,
  })),
);

const oldHomeRoute = [
  {
    props: { exact: true, path: '/' },
    component: <HomeMenu />,
  },
  {
    props: { exact: true, path: '/:siteID' },
    component: <HomeGroup />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <HomeMenu />,
  },
  {
    props: { exact: true, path: '/alternate' },
    component: <HomeGroup />,
  },
];

const newHomeRoute = [
  {
    props: { exact: true, path: '/' },
    component: <HomeGroup />,
  },
  {
    props: { exact: true, path: '/home/:siteID' },
    component: <HomeGroup />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <HomeGroup />,
  },
  {
    props: { exact: true, path: '/alternate' },
    component: <HomeMenu />,
  },
];

const defaultHomeRoutes = newHomeRoute;

const homeRoutes = [...defaultHomeRoutes];

export default homeRoutes;
