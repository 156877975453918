import React, { lazy } from 'react';

const TaxTypeListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/TaxModule/TaxTypeListing'
  ).then(module => ({
    default: module.TaxTypeListing,
  })),
);

const TaxSchemeListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/TaxModule/TaxSchemeListing'
  ).then(module => ({
    default: module.TaxSchemeListing,
  })),
);

const TaxEffectiveDateForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/TaxModule/TaxEffectiveDateForm'
  ).then(module => ({
    default: module.TaxEffectiveDateForm,
  })),
);

const taxRoutes = [
  {
    props: { exact: true, path: '/system-admin/general-settings/tax-type' },
    component: <TaxTypeListing software="Site" />,
  },
  {
    props: {
      exact: true,
      path: '/system-admin/general-settings/tax-type/:taxTypeID/tax-scheme',
    },
    component: <TaxSchemeListing software="Site" />,
  },
  {
    props: {
      exact: true,
      path: '/system-admin/general-settings/tax-type/:taxTypeID/tax-scheme/tax-effective-date/add',
    },
    component: <TaxEffectiveDateForm software="Site" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/system-admin/general-settings/tax-type/:taxTypeID/tax-scheme/:taxSchemeID/tax-effective-date/edit',
    },
    component: <TaxEffectiveDateForm software="Site" mode="edit" />,
  },
];

export default taxRoutes;
