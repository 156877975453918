import React, { lazy } from 'react';

const SiteSurveillanceSubmenu = lazy(() =>
  import('./SiteSurveillanceSubmenu').then(module => ({
    default: module.SiteSurveillanceSubmenu,
  })),
);

const SiteImagesListing = lazy(() =>
  import('./SiteImages/SiteImagesListing').then(module => ({
    default: module.SiteImagesListing,
  })),
);

const SiteImagesForm = lazy(() =>
  import('./SiteImages/SiteImagesForm').then(module => ({
    default: module.SiteImagesForm,
  })),
);

const SiteVideosListing = lazy(() =>
  import('./SiteVideos/SiteVideosListing').then(module => ({
    default: module.SiteVideosListing,
  })),
);

const siteSurveillanceRoutes = [
  {
    props: { exact: true, path: '/site-surveillance/submenu' },
    component: <SiteSurveillanceSubmenu />,
  },
  {
    props: { exact: true, path: '/site-surveillance/submenu/:siteID' },
    component: <SiteSurveillanceSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/site-surveillance/submenu/:siteID/site-images',
    },
    component: <SiteImagesListing />,
  },
  {
    props: {
      exact: true,
      path: '/site-surveillance/submenu/:siteID/site-images/add',
    },
    component: <SiteImagesForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/site-surveillance/submenu/:siteID/site-images/edit/:siteImageID',
    },
    component: <SiteImagesForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/site-surveillance/submenu/:siteID/site-videos',
    },
    component: <SiteVideosListing />,
  },
];

export default siteSurveillanceRoutes;
