import React, { lazy } from 'react';

const BusinessInsightSubmenu = lazy(() =>
  import('./BusinessInsightSubmenu').then(module => ({
    default: module.BusinessInsightSubmenu,
  })),
);

const DefectStatusAnalysis = lazy(() =>
  import('./DefectStatusAnalysis/DefectStatusAnalysis').then(module => ({
    default: module.DefectStatusAnalysis,
  })),
);

const DefectTradeAnalysis = lazy(() =>
  import('./DefectTradeAnalysis/DefectTradeAnalysis').then(module => ({
    default: module.DefectTradeAnalysis,
  })),
);

const DefectSubconAnalysis = lazy(() =>
  import('./DefectSubconAnalysis/DefectSubconAnalysis').then(module => ({
    default: module.DefectSubconAnalysis,
  })),
);

const DefectLodgeOverTime = lazy(() =>
  import('./DefectLodgeOverTime/DefectLodgeOverTime').then(module => ({
    default: module.DefectLodgeOverTime,
  })),
);

const SiteWorkerAnalysis = lazy(() =>
  import('./SiteWorkerAnalysis/SiteWorkerAnalysis').then(module => ({
    default: module.SiteWorkerAnalysis,
  })),
);

const ManLabourAnalysis = lazy(() =>
  import('./ManLabourAnalysis/ManLabourAnalysis').then(module => ({
    default: module.ManLabourAnalysis,
  })),
);

const WageLabourCostAnalysis = lazy(() =>
  import('./WageLabourCostAnalysis/WageLabourCostAnalysis').then(module => ({
    default: module.WageLabourCostAnalysis,
  })),
);

const StockUsageDailyAnalysis = lazy(() =>
  import('./StockUsageDailyAnalysis/StockUsageDailyAnalysis').then(module => ({
    default: module.StockUsageDailyAnalysis,
  })),
);

const StockUsageMonthlyAnalysis = lazy(() =>
  import('./StockUsageMonthlyAnalysis/StockUsageMonthlyAnalysis').then(
    module => ({
      default: module.StockUsageMonthlyAnalysis,
    }),
  ),
);

const StockMoveInOutAnalysis = lazy(() =>
  import('./StockMoveInOutAnalysis/StockMoveInOutAnalysis').then(module => ({
    default: module.StockMoveInOutAnalysis,
  })),
);

const StockUsageSummary = lazy(() =>
  import('./SiteUsageSummary/SiteUsageSummary').then(module => ({
    default: module.StockUsageSummary,
  })),
);

const SiteUsageComparison = lazy(() =>
  import('./SiteUsageComparison/SiteUsageComparison').then(module => ({
    default: module.SiteUsageComparison,
  })),
);

const SiteWorkerAttendanceTracking = lazy(() =>
  import('./SiteWorkerAttendanceTracking/SiteWorkerAttendanceTracking').then(
    module => ({
      default: module.WorkerAttendanceTracking,
    }),
  ),
);

const businessInsightRoutes = [
  //non Siteid routes
  //non Siteid routes
  //non Siteid routes
  {
    props: { exact: true, path: '/business-insight/submenu' },
    component: <BusinessInsightSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/defect-status-analysis',
    },
    component: <DefectStatusAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/defect-trade-analysis',
    },
    component: <DefectTradeAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/defect-subcon-analysis',
    },
    component: <DefectSubconAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/defect-lodge-over-time',
    },
    component: <DefectLodgeOverTime mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/site-worker-analysis',
    },
    component: <SiteWorkerAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/man-labour-analysis',
    },
    component: <ManLabourAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/wage-labour-cost-analysis',
    },
    component: <WageLabourCostAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/stock-usage-daily-analysis',
    },
    component: <StockUsageDailyAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/stock-usage-monthly-analysis',
    },
    component: <StockUsageMonthlyAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/stock-move-in-out-analysis',
    },
    component: <StockMoveInOutAnalysis mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/site-usage-summary',
    },
    component: <StockUsageSummary mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/site-usage-comparison',
    },
    component: <SiteUsageComparison mode={'all'} />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/site-worker-attendance-tracking',
    },
    component: <SiteWorkerAttendanceTracking />,
  },

  // SiteiD Routes
  // SiteiD Routes
  // SiteiD Routes
  {
    props: { exact: true, path: '/business-insight/submenu/:siteID' },
    component: <BusinessInsightSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/defect-status-analysis',
    },
    component: <DefectStatusAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/defect-trade-analysis',
    },
    component: <DefectTradeAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/defect-subcon-analysis',
    },
    component: <DefectSubconAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/defect-lodge-over-time',
    },
    component: <DefectLodgeOverTime />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/site-worker-analysis',
    },
    component: <SiteWorkerAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/man-labour-analysis',
    },
    component: <ManLabourAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/wage-labour-cost-analysis',
    },
    component: <WageLabourCostAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/wage-labour-cost-analysis',
    },
    component: <WageLabourCostAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/stock-usage-daily-analysis',
    },
    component: <StockUsageDailyAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/stock-usage-monthly-analysis',
    },
    component: <StockUsageMonthlyAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/stock-move-in-out-analysis',
    },
    component: <StockMoveInOutAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/site-usage-summary',
    },
    component: <StockUsageSummary />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/site-usage-comparison',
    },
    component: <SiteUsageComparison />,
  },
  {
    props: {
      exact: true,
      path: '/business-insight/submenu/:siteID/site-worker-attendance-tracking',
    },
    component: <SiteWorkerAttendanceTracking />,
  },
];

export default businessInsightRoutes;
