import React, { lazy } from 'react';

const SiteAttendanceSubmenu = lazy(() =>
  import('./SiteAttendanceSubmenu').then(module => ({
    default: module.SiteAttendanceSubmenu,
  })),
);

const WorkerProfileListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WorkerProfileModule/WorkerProfileListing'
  ).then(module => ({
    default: module.WorkerProfileListing,
  })),
);

const WorkerAttendanceListing = lazy(() =>
  import('../SiteWagesModule/WorkerAttendance/WorkerAttendanceListing').then(
    module => ({
      default: module.WorkerAttendanceListing,
    }),
  ),
);

const WorkerProfileForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WorkerProfileModule/WorkerProfileForm'
  ).then(module => ({
    default: module.WorkerProfileForm,
  })),
);

// const WorkerProfileDetail = lazy(() =>
//   import('./WorkerProfile/WorkerProfileDetail').then(module => ({
//     default: module.WorkerProfileDetail,
//   }))
// )

const siteAttendanceRoutes = [
  {
    props: { exact: true, path: '/site-attendance/submenu/:siteID' },
    component: <SiteAttendanceSubmenu />,
  },
  {
    props: { exact: true, path: '/site-attendance/submenu' },
    component: <SiteAttendanceSubmenu />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/site-attendance/submenu/:siteID/worker-profile',
  //   },
  //   component: <WorkerProfileListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/site-attendance/submenu/:siteID/worker-profile/form',
  //   },
  //   component: <WorkerProfileForm mode="add" />,
  // },

  // {
  //   props: {
  //     exact: true,
  //     path: '/site-attendance/submenu/:siteID/worker-profile/:workerID/form',
  //   },
  //   component: <WorkerProfileForm mode="edit" />,
  // },
  {
    props: {
      exact: true,
      path: '/site-attendance/submenu/:siteID/worker-attendance',
    },
    component: <WorkerAttendanceListing />,
  },
];

export default siteAttendanceRoutes;
