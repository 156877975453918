import React, { lazy } from 'react';

const DocumentFolderListing = lazy(() =>
  import('./EDocListing').then(module => ({
    default: module.DocumentFolderListing,
  })),
);
const DocumentFileDetail = lazy(() =>
  import('./EDocumentFileDetail').then(module => ({
    default: module.DocumentFileDetail,
  })),
);

const eDocRoutes = [
  {
    props: { exact: true, path: '/e-document' },
    component: <DocumentFolderListing />,
  },
  {
    props: { exact: true, path: '/e-document/:siteID' },
    component: <DocumentFolderListing />,
  },
  {
    props: { exact: true, path: '/e-document/:siteID/folder/:menuID' },
    component: <DocumentFileDetail />,
  },
];

export default eDocRoutes;
