import React, { lazy } from 'react';

const SubcontractorSetupListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/SubcontractorSetupModule/SubcontractorSetupListing'
  ).then(module => ({
    default: module.SubcontractorSetupListing,
  })),
);

const SubcontractorSetupForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/SubcontractorSetupModule/SubcontractorSetupForm'
  ).then(module => ({
    default: module.SubcontractorSetupForm,
  })),
);

const SubcontractorSetupDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/SubcontractorSetupModule/SubcontractorSetupDetail'
  ).then(module => ({
    default: module.SubcontractorSetupDetail,
  })),
);

const subcontractorSetupRoutes = [
  {
    props: {
      exact: true,
      path: '/system-admin/subcontractor-setup',
    },
    component: <SubcontractorSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/system-admin/subcontractor-setup/add',
    },
    component: <SubcontractorSetupForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/system-admin/subcontractor-setup/edit/:subcontractorId',
    },
    component: <SubcontractorSetupForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/system-admin/subcontractor-setup/detail/:subcontractorId',
    },
    component: <SubcontractorSetupDetail />,
  },
];
export default subcontractorSetupRoutes;
