import React, { lazy } from 'react';

const SiteApprovalPolicyListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/SiteApprovalPolicy/SiteApprovalPolicyListing'
  ).then(module => ({
    default: module.SiteApprovalPolicyListing,
  })),
);

const SiteInventoryApprovalForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/SiteApprovalPolicy/SiteApprovalPolicyForm'
  ).then(module => ({
    default: module.SiteApprovalPolicyForm,
  })),
);

const commonSettingsRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/site-approval-policy/policy-list',
    },
    component: <SiteApprovalPolicyListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/site-approval-policy/policy-list/:policyType/modify',
    },
    component: <SiteInventoryApprovalForm />,
  },
];

export default commonSettingsRoutes;
