import { SitePermission } from 'generated/graphql'
import localForage from 'localforage'
import { useEffect, useState } from 'react'
/**
 * @param handlePermDisabled : {contractID: '' | contractID , permEnum: ContractPermission.HomeContractView}
 * @param handlePermHidden : {contractID: '' | contractID , permEnum: ContractPermission.HomeContractView}
 * @param permission : general permission list
 * @param contractPermission : contract permission list
 */
interface PermProps {
  contractID?: string
  permEnum: SitePermission
}
export const usePermissionChecker = () => {
  const [permission, setPermMatch] = useState({})
  const [sitePermission, setSitePerm] = useState({})
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  useEffect(() => {
    localForage?.getItem('rolePermission', (err, value) => {
      setPermMatch(value)
      // console.log(value);
    })
    // console.log('hello1')
  }, [setPermMatch])

  useEffect(() => {
    localForage?.getItem('sitePermission', (err, value) => {
      setSitePerm(value)
      // console.log(value);
    })
    // console.log('hello2')
  }, [setSitePerm])

  const handlePermDisabled = ({ contractID, permEnum }: PermProps) => {
    return !!contractID && !!sitePermission
      ? sitePermission[contractID]?.hasOwnProperty(permEnum) === false &&
          !user?.superUser
      : permission?.hasOwnProperty(permEnum) === false && !user?.superUser
  }

  const handlePermHidden = ({ contractID, permEnum }: PermProps) => {
    return contractID
      ? sitePermission[contractID]?.hasOwnProperty(permEnum) || user?.superUser
      : permission?.hasOwnProperty(permEnum) || user?.superUser
  }
  return {
    permission,
    sitePermission,
    handlePermDisabled,
    handlePermHidden,
  }
}
