import { yupResolver } from '@hookform/resolvers';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
  GetDefectAssignmentDocument,
  SitePermission,
  useCreateDefectAssignmentMutation,
  useDocumentListingLazyQuery,
  useGetDefectAssignmentQuery,
  useGetDefectDetailDataQuery,
  useGetSubcontractorCostCategoryLazyQuery,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import { CommonYupValidation } from 'helpers/YupSchema/yup';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';

interface defectReassignmentForm {
  trade: string;
  subcontractor: string;
  expectedDate: string;
  remarks: string;
  defectID: string;
}

export const DefectAssignmentListing = () => {
  const [defectReDialog, openDefectReDialog] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );
  const { handlePermDisabled } = usePermissionChecker();

  let history = useHistory();

  const { siteID, defectID }: any = useParams();

  const [errorMsg, setErrorMsg] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const [destructureType, setDestructureType] = useState('');

  const defectReassignmentSchema = yup.object().shape({
    trade: CommonYupValidation.requireField(SystemMsgs.trade()),
    subcontractor: CommonYupValidation.requireField(
      SystemMsgs.subcontractSelection(),
    ),
    expectedDate: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
  });

  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    defectReassignmentForm
  >({
    mode: 'onBlur',
    resolver: yupResolver(defectReassignmentSchema),
  });

  const {
    loading: defectAssignmentLoading,
    data: { getDefectAssignment } = { getDefectAssignment: [] },
  } = useGetDefectAssignmentQuery({
    fetchPolicy: 'network-only',
    variables: {
      defectID: defectID,
    },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
      setDestructureType('query');
    },
  });

  const {
    loading: defectDetailLoading,
    error: defectDetailError,
    data: { getDefect, getCostCategory } = {
      getDefect: [],
      getCostCategory: [],
    },
  } = useGetDefectDetailDataQuery({
    fetchPolicy: 'network-only',
    variables: {
      siteID,
      defectID,
    },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
      setDestructureType('query');
    },
  });

  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment();

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    variables: { refID: defectID },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing);
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL));
    },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
      setDestructureType('query');
    },
  });

  const [
    fetchSubCon,
    {
      loading: subcontractorCCLoading,
      data: { getSubcontractorCostCategory } = {
        getSubcontractorCostCategory: [],
      },
    },
  ] = useGetSubcontractorCostCategoryLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
      setDestructureType('query');
    },
  });

  const [createDefectAssignment] = useCreateDefectAssignmentMutation({
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
      setDestructureType('mutation');
    },
    onCompleted: data => {
      setOpenSnackBar(true);
      setSnackBarMsg(SystemMsgs.createNewRecord());
      openDefectReDialog(false);
      setTimeout(() => {
        setOpenSnackBar(false);
      }, 3000);
    },
  });

  const onSubmit = data => {
    createDefectAssignment({
      variables: {
        ID: defectID,
        input: {
          costCategoryID: data.trade,
          subcontractorID: data.subcontractor,
          assignmentDate: new Date().toISOString(),
          expectedDate: new Date(data.expectedDate).toISOString(),
          remarks: data.remarks,
          file: files,
        },
      },
      refetchQueries: [
        {
          query: GetDefectAssignmentDocument,
          variables: {
            defectID: defectID,
          },
        },
      ],
    });
  };

  console.log(errors, 'erros');

  return (
    <>
      {defectAssignmentLoading && <Loading />}
      {defectDetailLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/defect/${siteID}/detail/${defectID}`)}
        smTitle="Defect Assignment"
        title={getDefect[0]?.site?.name}
        routeSegments={[
          { name: `Defects` },
          { name: `Defect Details` },
          { name: `Defect Assignment`, current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          getDefectAssignment[getDefectAssignment?.length - 1]?.subcontractor
            ?.name
        }
        rightText={formatDate(
          getDefectAssignment[getDefectAssignment?.length - 1]?.assignmentDate,
        )}
      />

      <ContentWrapper singleDynamicInfo>
        <List className="core-list">
          {getDefectAssignment === undefined ||
          getDefectAssignment.length === 0 ? (
            <EmptyList
              title="No Record Found"
              subtitle="Add a new record now."
            ></EmptyList>
          ) : (
            getDefectAssignment?.map((el, index) => {
              return (
                <>
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <>
                          <span className="xsTitle">
                            {el?.subcontractor?.name}&nbsp;
                          </span>

                          <span
                            className="desc"
                            style={{
                              position: 'absolute',
                              right: '0px',
                              paddingLeft: '50px',
                              paddingRight: '10px',
                            }}
                          >
                            <span className="highlight-text">
                              {formatDate(el?.assignmentDate)}
                            </span>
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <div className="flex-space">
                            <div className="desc full text-noflow ">
                              {el?.defect?.trade?.name || 'N/A'} |{' '}
                              {el?.remarks || 'N/A'}
                            </div>
                          </div>
                          {/* <div className="flex-space">
                            <div className="desc text-noflow">
                              {el?.remarks || 'N/A'}
                            </div>
                          </div> */}
                          <span
                            className="desc"
                            style={{
                              position: 'absolute',
                              right: '0px',
                              paddingLeft: '50px',
                              paddingRight: '10px',
                            }}
                          >
                            Exp:{' '}
                            <span className="highlight-text">
                              {formatDate(el?.expectedDate)}
                            </span>
                          </span>
                        </>
                      }
                    ></ListItemText>
                  </ListItem>
                </>
              );
            })
          )}
        </List>
      </ContentWrapper>

      <CommonDialog
        fullWidth={true}
        open={defectReDialog}
        onClose={() => openDefectReDialog(false)}
        sections={{
          header: {
            children: (
              <>
                <ListItemText
                  primary={
                    <>
                      <div className="dialog-dynamic-content">
                        <div className="session">
                          <div className="flex session">
                            <div className="title highlight-text  c-darkblue">
                              <span>{getDefect[0]?.docNo}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  secondary={
                    <>
                      <span
                        className="xsTitle flex-space infoline-content"
                        style={{ color: 'black' }}
                      >
                        Defect Assignment
                      </span>
                    </>
                  }
                />
              </>
            ),
          },

          body: () => (
            <>
              <form>
                <CardContents>
                  {/* <Controller
                    as={
                      <TextField
                        select
                        required={true}
                        fullWidth
                        autoComplete="off"
                        multiline={true}
                        margin="normal"
                        inputRef={register}
                        onChange={e => {
                          console.log(e?.target?.value, 'ACCC')
                          setValue('trade', e?.target?.value)
                          fetchSubCon({
                            variables: {
                              costCategoryID: e?.target?.value as any,
                            },
                          })
                        }}
                      >
                        {getCostCategory?.map((el, index) => (
                          <MenuItem key={index} value={el?.ID}>
                            {el?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                    name="trade"
                    label="Trade"
                    control={control}
                    ref={register}
                    helperText={errors?.trade?.message}
                    error={errors?.trade ? true : false}
                  /> */}

                  <Controller
                    render={({ onChange, onBlur, value }) => (
                      <TextField
                        name="trade"
                        label="Trade"
                        margin="normal"
                        select
                        onChange={e => {
                          console.log(e?.target?.value, 'ACCC');
                          setValue('trade', e?.target?.value);
                          fetchSubCon({
                            variables: {
                              costCategoryID: e?.target?.value as any,
                            },
                          });
                        }}
                      >
                        {getCostCategory?.map((el, index) => (
                          <MenuItem key={index} value={el?.ID}>
                            {el?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    required
                    select
                    name="trade"
                    label="Trade"
                    control={control}
                    ref={register}
                    autoComplete="off"
                    fullWidth
                    helperText={errors?.trade?.message}
                    error={errors?.trade ? true : false}
                  />

                  <Controller
                    as={
                      <TextField
                        select
                        required={true}
                        fullWidth
                        autoComplete="off"
                        multiline={true}
                        margin="normal"
                        inputRef={register}
                      >
                        {getSubcontractorCostCategory?.map((x, index) => (
                          <MenuItem key={index} value={x?.subcontractorID}>
                            {x?.subcontractorName}
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                    name="subcontractor"
                    label="Assign To"
                    control={control}
                    ref={register}
                    helperText={errors?.subcontractor?.message}
                    error={errors?.subcontractor ? true : false}
                  />

                  <Controller
                    as={
                      <KeyboardDatePicker
                        required={true}
                        disableToolbar
                        variant="inline"
                        format="dd MMM yyyy "
                        margin="normal"
                        id="date-picker-inline"
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDate={getDefectAssignment[0]?.assignmentDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    }
                    name="expectedDate"
                    label="Expected Delivery Date"
                    control={control}
                    ref={register}
                    defaultValue={new Date().toISOString()}
                    helperText={errors?.expectedDate?.message}
                    error={errors?.expectedDate ? true : false}
                  />
                  <Controller
                    as={<TextField multiline={true} margin="normal" />}
                    name="remarks"
                    label="Remarks"
                    control={control}
                    ref={register}
                    helperText={errors?.remarks?.message}
                    error={errors?.remarks ? true : false}
                  />
                  <FileUploadInput
                    label="Upload Attachment(s)"
                    files={files}
                    onHandleUploadChange={handleUploadChange}
                    multiple
                    imagePreview={
                      <>
                        {previewFiles?.map((v, i) => (
                          <UploadPreview
                            remove
                            key={v}
                            src={v}
                            onClick={() => removeFile(i)}
                            mediaType={
                              files[i]?.type ??
                              DocData?.DocumentListing[i]?.mediaType
                            }
                          />
                        ))}
                      </>
                    }
                  />
                </CardContents>
              </form>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => openDefectReDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleSubmit(onSubmit)();
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      ></CommonDialog>
      <FloatButton
        onClick={() => {
          openDefectReDialog(true);
        }}
        disabled={handlePermDisabled({
          permEnum: SitePermission.DefectMgmtDefectAssignmentCreate,
        })}
      />

      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg={`Defect Assignment ${
          destructureType === 'mutation' ? 'Setup' : 'Listing Display'
        } Error`}
      />
    </>
  );
};
