import React, { lazy } from 'react';

const SiteMaterialPolicyListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/SiteMaterialModule/ApprovalPolicy/SiteMaterialPolicyListing'
  ).then(module => ({
    default: module.SiteMaterialPolicyListing,
  })),
);

const siteMaterialPolicyRoutes = [
  {
    props: { exact: true, path: '/:mode/site-material-policy' },
    component: <SiteMaterialPolicyListing />,
  },
];

export default siteMaterialPolicyRoutes;
