import React, { lazy } from 'react';

const SiteSafetySubmenu = lazy(() =>
  import('./SiteSafetySubmenu').then(module => ({
    default: module.SiteSafetySubmenu,
  })),
);

const siteSafetyRoutes = [
  {
    props: { exact: true, path: '/site-safety/submenu' },
    component: <SiteSafetySubmenu />,
  },
  {
    props: { exact: true, path: '/site-safety/submenu/:siteID' },
    component: <SiteSafetySubmenu />,
  },
];

export default siteSafetyRoutes;
