import React, { lazy } from 'react';
import calendarPolicyRoutes from './RoutesModule/CalendarPolicyRoutes';
import costCategoryRoutes from './RoutesModule/CostCategoryRoutes';
import costItemRoutes from './RoutesModule/CostItemRoutes';
import jobTypeRoutes from './RoutesModule/JobTypeRoutes';
import publicHolidayRoutes from './RoutesModule/PublicHolidayRoutes';
import siteMaterialRoutes from './RoutesModule/SiteMaterialRoutes';
import videoCamRoutes from './RoutesModule/VideoCamRoutes';
import wagesRatePolicyRoutes from './RoutesModule/WagesRatePolicyRoutes';
import workerProfileRoutes from './RoutesModule/WorkerProfileRoutes';
import workingHoursRoutes from './RoutesModule/WorkingHoursRoutes';

//General Settings//
const GeneralSettingListing = lazy(() =>
  import('./GeneralSettingListing').then(module => ({
    default: module.GeneralSettingListing,
  })),
);

const generalSettingRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings' },
    component: <GeneralSettingListing />,
  },
  ...publicHolidayRoutes,
  ...videoCamRoutes,
  ...workingHoursRoutes,
  ...calendarPolicyRoutes,
  ...jobTypeRoutes,
  ...siteMaterialRoutes,
  ...wagesRatePolicyRoutes,
  ...workerProfileRoutes,
  ...costItemRoutes,
  ...costCategoryRoutes,
];

export default generalSettingRoutes;
