import React, { lazy } from 'react';
// import { ToDoListing } from './ToDoListing';

const ToDoListing = lazy(() =>
  import('./ToDoListing').then(module => ({
    default: module.ToDoListing,
  })),
);

const toDoRoutes = [
  {
    props: { exact: true, path: '/to-do' },
    component: <ToDoListing />,
  },
  {
    props: { exact: true, path: '/to-do/:siteID' },
    component: <ToDoListing />,
  },
];

export default toDoRoutes;
